import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { Card, Row, Col } from 'antd';
import Layout from './../components/Layout'

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map(post => (
      <li key={post.node.fields.slug}>
        <Link className="button" to={post.node.fields.slug}>
          {post.node.frontmatter.title}
        </Link>
      </li>
    ))
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`

    return (
      <Layout>
        <Row gutter={24}>
          <Helmet title={`${tag} | ${title}`} />
          <Col
            lg={{
              span: 18,
              offset: 3
            }}
            md={{
              span: 18,
              offset: 3
            }}
            sm={{
              span: 24,
              offset: 0
            }}
            xs={{
              span: 24,
              offset: 0
            }}
          >
            <Card title={tagHeader}>
              <ul className="taglist">{postLinks}</ul>
              <p>
                <Link className="button" to="/tags/">See All Topics</Link>
              </p>
            </Card>
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
